import React, {useMemo} from 'react';
import CasinoTabs, {
  CasinoTabItem,
} from '@/components/v2/business/tabs/casino-tabs';
import {LotteryGameFloorItem} from '../lottery.service';
// import {Image} from 'antd-mobile';
import {ReactComponent as DiceSvg} from '@assets/svgs/lottery-menu/dice.svg';
import {ReactComponent as ColorSvg} from '@assets/svgs/lottery-menu/color.svg';
import {ReactComponent as DigitSvg} from '@assets/svgs/lottery-menu/digit.svg';
import {ReactComponent as StateLotterySvg} from '@assets/svgs/lottery-menu/state-lottery.svg';

interface CategoryTabProp {
  activeKey: string;
  onChange: (key: string) => void;
  tabs?: LotteryGameFloorItem[];
  className?: string;
}

export const iconsMap: Record<
  string,
  {active: React.ReactNode; default: React.ReactNode}
> = {
  diceV3: {
    active: <DiceSvg className="text-white size-6" />,
    default: <DiceSvg className="text-t2 size-6" />,
  },
  colorV3: {
    active: <ColorSvg className="text-white size-6" />,
    default: <ColorSvg className="text-t2 size-6" />,
  },
  '3DigitV3': {
    active: <DigitSvg className="text-white size-6" />,
    default: <DigitSvg className="text-t2 size-6" />,
  },
  indianLottery: {
    active: <StateLotterySvg className="text-white size-6" />,
    default: <StateLotterySvg className="text-t2 size-6" />,
  },
};

export default function CategoryTab({
  tabs,
  activeKey,
  ...otherProps
}: CategoryTabProp) {
  const options = useMemo<CasinoTabItem[]>(() => {
    return (
      tabs?.map(tab => ({
        icon: iconsMap[tab.uniqueKey]
          ? tab.uniqueKey === activeKey
            ? iconsMap[tab.uniqueKey].active
            : iconsMap[tab.uniqueKey].default
          : null,
        title: tab.name,
        tabKey: tab.uniqueKey,
      })) || []
    );
  }, [tabs, activeKey]);
  return <CasinoTabs options={options} activeKey={activeKey} {...otherProps} />;
}
