import theme from '@style';
import {StyleSheet} from 'react-native';
const drawImageWrapPadding = 22;
const drawImageWidth = 265;
const drawImageHeight = 268;
export const needleWidth = 54;
export const needleHeight = 98;
export const ITEM_HEIGHT = 25;
export const titleIcon = require('@assets/imgs/luckyspin/title.png');
export const spinWrapIcon = require('@assets/imgs/luckyspin/spin-wrap.png');
export const needleIcon = require('@assets/imgs/luckyspin/needle.png');
export const baseIcon = require('@assets/imgs/luckyspin/base.png');
export const copperIcon = require('@assets/imgs/luckyspin/copper.png');
export const resultIcon = require('@assets/imgs/luckyspin/result.png');
export const moneyIcon = require('@assets/imgs/luckyspin/money.png');
export const buttonBlueIcon = require('@assets/imgs/luckyspin/button-blue.png');
export const buttonGreenIcon = require('@assets/imgs/luckyspin/button-green.png');
export const vipIcon = require('@assets/imgs/luckyspin/more-vip.webp');

export const closeIcon = require('@assets/imgs/luckyspin/button-close.png');
export const rotateAudio = require('@assets/imgs/luckyspin/rotate.mp3');
export const openAudio = require('@assets/imgs/luckyspin/open.mp3');
export const styles = StyleSheet.create({
  closeButton: {
    top: -20,
    right: -20,
  },
  drawImageWrap: {
    padding: drawImageWrapPadding,
  },
  needle: {
    top: drawImageHeight / 2 - 70 + drawImageWrapPadding,
    left: (drawImageWidth - needleWidth) / 2 + drawImageWrapPadding,
    zIndex: 1,
  },
  base: {
    bottom: -60,
    left: 4,
    zIndex: -3,
  },
  copper: {
    bottom: 0,
    left: 75,
    zIndex: 1,
  },
  result: {
    marginTop: 53,
    zIndex: -2,
  },
  drawImage: {
    width: drawImageWidth,
    height: drawImageHeight,
    zIndex: -3,
  },
  tabWrap: {
    backgroundColor: '#521a1b',
    borderRadius: theme.borderRadiusSize.xs,
  },
  tabActive: {
    backgroundColor: '#e67053',
    borderRadius: theme.borderRadiusSize.l,
  },
  nonepadding: {
    paddingVertical: 0,
  },
  tabActiveText: {
    color: 'white',
    paddingHorizontal: 0,
  },
  tabNotActiveText: {
    color: '#e05a5e',
    paddingHorizontal: 0,
  },
  resultList: {
    height: 100,
    marginHorizontal: theme.paddingSize.xl,
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
  },
  resultItem: {
    height: ITEM_HEIGHT,
  },
  buy: {
    color: '#43cf7c',
  },
  won: {
    color: '#ffeb3b',
  },
});
