import theme from '@style';
import {ToastType, useModal, useToast} from '@basicComponents/modal';
import {
  FlatList,
  ListRenderItemInfo,
  NativeScrollEvent,
  NativeSyntheticEvent,
  View,
} from 'react-native';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import LazyImage, {LazyImageBackground} from '@basicComponents/image';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Animated} from 'react-native';
import Text from '@basicComponents/text';
import {goTo, toPriceStr} from '@utils';
import LinearGradient from '@basicComponents/linear-gradient';
import {Tab} from '@rneui/themed';
import globalStore from '@services/global.state';
import {
  postRankingList,
  postSpinConfig,
  postSpinOrderCreate,
  postSpinOrderList,
} from './luckyspin.service';
import {SafeAny} from '@/types';
import {
  RankingItem,
  RankingList,
  SpinOrderItem,
  SpinOrderList,
} from './luckyspin.service';
import Sound from '@basicComponents/sound';
import {useGetModal} from './getmodal.hooks';
import {
  ITEM_HEIGHT,
  baseIcon,
  buttonBlueIcon,
  buttonGreenIcon,
  closeIcon,
  copperIcon,
  moneyIcon,
  needleHeight,
  needleIcon,
  needleWidth,
  openAudio,
  resultIcon,
  rotateAudio,
  spinWrapIcon,
  styles,
  titleIcon,
} from './luckyspin.style';
import BottomInfo from './components/bottom-info';
import {useTranslation} from 'react-i18next';
import Price from '@/components/business/price';

// TODO 前面四个参数暂时保留，待对应三个盘引用都不再引用这个将删除，目前这个已改为内部发起请求进行处理；
interface LuckySpinModalProps {
  singleAmount?: number;
  batchCount?: number;
  freeCount?: number;
  drawImage?: string;
  onNotice?: () => Function | void;
}

export function useLuckySpinModal({onNotice}: LuckySpinModalProps) {
  const {i18n} = useTranslation();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [rankingList, setRankingList] = useState<RankingList>([]);
  const [spinOrderList, setSpinOrderList] = useState<SpinOrderList>([]);
  const animateTiming = useRef<Animated.CompositeAnimation>();
  const [animating, setAnimating] = useState<boolean>(false);
  const [canClick, setCanClick] = useState<boolean>(true);
  const rotateSound = useRef<Sound>();
  const openSound = useRef<Sound>();
  const [amount, setAmount] = useState(0);
  const canclickTimeout = useRef<NodeJS.Timeout>();

  const [drawImage, setDrawImage] = useState('');
  const [singleAmount, setSpinBasePrice] = useState(10);
  const [batchCount, setSpinBatchCount] = useState(30);
  const [freeCount, setFreeCount] = useState(0);

  const refresh = () => {
    globalStore.updateAmount.next({gameType: 'BigTurntable'});
  };
  const {renderModal: renderGetModal, show: getModalShow} =
    useGetModal(refresh);
  const {renderModal: renderToast, show: toastShow} = useToast();
  const rankIndex = useRef<number>(0);
  const rankTiming = useRef<NodeJS.Timeout>();
  const rankRef = useRef<FlatList>(null);
  const [login, setLogin] = useState(false);
  const degreeAnim = useRef<Animated.Value>(new Animated.Value(0)).current;
  const degreeResult = degreeAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });
  useEffect(() => {
    if (!showModal) {
      globalStore.updateAmount.next({});
      return;
    }
    rotateSound.current = new Sound(rotateAudio);
    openSound.current = new Sound(openAudio);
    refresh();

    const sub = globalStore.amountChanged.subscribe(res => {
      if (res.current != null) {
        setAmount(res.current);
      }
    });

    const tokenSub = globalStore.tokenSubject.subscribe(token => {
      setLogin(!!token);
      // 进入后预先赋值使用本地存储的图片url
      globalStore.asyncGetItem('luckyspinBackground').then(luckyspinBg => {
        if (luckyspinBg) {
          setDrawImage(luckyspinBg);
        }
      });
      postSpinConfig(!!token).then(data => {
        setDrawImage(data?.image);
        setSpinBasePrice(data?.singleAmount);
        setSpinBatchCount(data?.batchCount);
        setFreeCount(data?.myFree || 0);
        if (data?.image != null && data.image !== '') {
          globalStore.asyncSetItem('luckyspinBackground', data?.image);
        }
      });
    });

    return () => {
      rankIndex.current = 0;
      if (animateTiming.current) {
        animateTiming.current.stop();
        animateTiming.current = undefined;
      }

      if (rotateSound.current) {
        rotateSound.current.release();
        rotateSound.current = undefined;
      }

      if (openSound.current) {
        openSound.current.release();
        openSound.current = undefined;
      }

      if (canclickTimeout.current) {
        clearTimeout(canclickTimeout.current);
        canclickTimeout.current = undefined;
      }

      sub.unsubscribe();
      tokenSub.unsubscribe();

      setAnimating(false);
      setCanClick(true);
    };
  }, [showModal]);
  const updateRankIndex = useCallback(() => {
    rankTiming.current = setTimeout(() => {
      if (
        rankRef.current &&
        rankingList?.length > 4 &&
        rankIndex.current < rankingList?.length - 1
      ) {
        rankIndex.current++;
        rankRef.current.scrollToIndex({
          index: rankIndex.current,
          animated: true,
        });
        updateRankIndex();
      }
    }, 2000);
  }, [rankingList?.length]);
  useEffect(() => {
    if (
      showModal &&
      tabIndex === 0 &&
      rankingList?.length > 4 &&
      rankIndex.current < rankingList?.length
    ) {
      updateRankIndex();
    } else {
      if (rankTiming.current) {
        clearTimeout(rankTiming.current);
      }
    }

    return () => {
      if (rankTiming.current) {
        clearTimeout(rankTiming.current);
      }
    };
  }, [rankingList?.length, showModal, tabIndex, updateRankIndex]);
  const handleRankingScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    const y = e.nativeEvent.contentOffset.y;
    const nowIndex = Math.floor(y / ITEM_HEIGHT);
    rankIndex.current = nowIndex;
  };
  const handleChange = (value: number) => {
    setTabIndex(value);
  };
  const updateSpinOrderList = () => {
    postSpinOrderList({pageNo: 1, pageSize: 1000}).then(list =>
      setSpinOrderList(list),
    );
  };
  const handlePrize = async (count: number) => {
    if (!login) {
      handleHide();
      goTo('Login');
      return;
    }
    if (animating) {
      // 如果在动画中，创建失效
      return;
    }
    //不许点击的情况
    if (!canClick) {
      return;
    }
    setCanClick(false);
    canclickTimeout.current = setTimeout(() => {
      setCanClick(true);
    }, 1000);
    if (count > 1 && freeCount > 0) {
      globalStore.globalWaringTotal(i18n.t('luckyspin.usefreefirst'));
      return;
    }
    try {
      globalStore.globalLoading.next(true);
      const result = await postSpinOrderCreate(count, singleAmount);
      const cb = onNotice?.();
      if (count === 1) {
        let rotate = (result.prizeIndex + 0.5) / 8 + 10;
        animateTiming.current = Animated.timing(degreeAnim, {
          duration: 5000,
          toValue: rotate,
          useNativeDriver: true,
        });
        setAnimating(true);
        rotateSound.current?.play();
        requestAnimationFrame(() => {
          animateTiming.current?.start(() => {
            rotateSound.current?.stop();
            openSound.current?.play();
            setAnimating(false);
            cb && cb();
            globalStore.updateAmount.next({gameType: 'BigTurntable'});
            degreeAnim.setValue(rotate - Math.floor(rotate));
            getModalShow(+result.prizeAmount);
            tabIndex === 1 && updateSpinOrderList();
            setFreeCount(_count => (_count > 0 ? _count - 1 : 0));
          });
        });
      } else {
        getModalShow(+result.prizeAmount);
        openSound.current?.play();
        globalStore.updateAmount.next({gameType: 'BigTurntable'});
        tabIndex === 1 && updateSpinOrderList();
        cb && cb();
      }
    } catch (e) {
      // console.log('error', e);
      if (
        (e as SafeAny).data.code === -1 ||
        (e as SafeAny).data.code === 5509
      ) {
        handleHide();
        return;
      }
      toastShow({
        type: ToastType.warning,
        message: (e as SafeAny).data.msg,
      });
    } finally {
      globalStore.globalLoading.next(false);
    }
  };
  useEffect(() => {
    if (!showModal) {
      return;
    }
    if (tabIndex === 1) {
      !!login && updateSpinOrderList();
    } else {
      postRankingList().then(list => setRankingList(list));
    }
  }, [tabIndex, showModal, login]);

  const renderItem = ({
    item,
  }: ListRenderItemInfo<RankingItem | SpinOrderItem>) => {
    return (
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.between,
          styles.resultItem,
          theme.padding.lrxl,
        ]}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          {isRankItem(item) ? (
            <Text style={[theme.font.fs, {color: 'white'}]}>
              {item.userPhone}
            </Text>
          ) : (
            <>
              <Text style={[theme.font.fs, styles.buy, theme.margin.rightxxs]}>
                buy
              </Text>
              <Text style={[theme.font.fs, {color: 'white'}]}>
                {toPriceStr(+item.betAmount, {thousands: true})}
              </Text>
            </>
          )}
        </View>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Text
            style={[theme.font.fs, {color: 'white'}, theme.margin.rightxxs]}>
            {i18n.t('luckyspin.won')}
          </Text>
          <Text style={[theme.font.fs, styles.won]}>
            {toPriceStr(+item.prizeAmount, {thousands: true})}
          </Text>
        </View>
      </View>
    );
  };
  const {renderModal, show, hide} = useModal(
    <View style={[theme.position.rel, theme.flex.col, theme.flex.centerByCol]}>
      <NativeTouchableOpacity
        style={[theme.position.abs, styles.closeButton]}
        onPress={() => handleHide()}>
        <LazyImage
          occupancy={'transparent'}
          imageUrl={closeIcon}
          width={40}
          height={40}
        />
      </NativeTouchableOpacity>
      <LazyImage
        occupancy={'transparent'}
        imageUrl={titleIcon}
        width={290}
        height={66}
      />
      <LazyImageBackground
        occupancy={'transparent'}
        imageUrl={spinWrapIcon}
        width={310}
        height={313}
        style={[styles.drawImageWrap]}>
        {!!drawImage && (
          <Animated.Image
            source={{uri: drawImage}}
            style={[styles.drawImage, {transform: [{rotate: degreeResult}]}]}
            resizeMode={'stretch'}
          />
        )}
        <View style={[theme.position.abs, styles.needle]}>
          <LazyImage
            occupancy={'transparent'}
            width={needleWidth}
            height={needleHeight}
            imageUrl={needleIcon}
          />
        </View>
        <View style={[theme.position.abs, styles.copper]}>
          <LazyImageBackground
            occupancy={'transparent'}
            imageUrl={copperIcon}
            width={160}
            height={32}
            style={[theme.flex.center, theme.flex.row]}>
            <LazyImage
              occupancy={'transparent'}
              imageUrl={moneyIcon}
              width={18}
              height={18}
            />
            <Price
              style={[theme.margin.rightm]}
              textProps={{fontSize: theme.fontSize.m, color: 'black'}}
              price={login ? amount : undefined}
            />

            <NativeTouchableOpacity
              onPress={() => {
                if (!login) {
                  handleHide();
                  goTo('Login');
                  return;
                }
                if (animating) {
                  return;
                }

                goTo('Recharge');
                handleHide();
              }}>
              <LinearGradient
                start={{x: 0, y: 0}}
                end={{x: 0, y: 1}}
                colors={['#f83600', '#fcc065']}
                style={[
                  theme.borderRadius.xs,
                  theme.padding.tbxxs,
                  theme.padding.lrxs,
                ]}>
                <Text fontSize={theme.fontSize.xs} style={[{color: 'white'}]}>
                  {i18n.t('luckyspin.add')}
                </Text>
              </LinearGradient>
            </NativeTouchableOpacity>
          </LazyImageBackground>
        </View>
        <View style={[theme.position.abs, styles.base]}>
          <LazyImageBackground
            imageUrl={baseIcon}
            occupancy={'transparent'}
            width={301}
            height={95}
            style={[
              theme.flex.row,
              theme.flex.centerByRow,
              theme.flex.alignEnd,
              theme.padding.btmm,
            ]}>
            <NativeTouchableOpacity
              activeOpacity={animating ? 1 : 0.8}
              onPress={() => handlePrize(1)}>
              <LazyImageBackground
                occupancy={'transparent'}
                imageUrl={buttonGreenIcon}
                width={125}
                height={46}
                style={[theme.flex.col, theme.flex.centerByCol]}>
                <View style={[theme.flex.row, theme.flex.center]}>
                  <LazyImage
                    occupancy={'transparent'}
                    imageUrl={moneyIcon}
                    width={25}
                    height={25}
                  />
                  <Text
                    fontSize={theme.fontSize.xl}
                    style={[theme.font.bold, {color: 'white'}]}>
                    x{singleAmount}
                  </Text>
                </View>
                <Text
                  fontSize={theme.fontSize.s}
                  style={[theme.font.bold, {color: 'white'}]}>
                  {freeCount
                    ? `${i18n.t('luckyspin.free')}(${freeCount})`
                    : i18n.t('luckyspin.spin')}
                </Text>
              </LazyImageBackground>
            </NativeTouchableOpacity>
            <NativeTouchableOpacity
              style={[theme.margin.leftxl]}
              activeOpacity={animating ? 1 : 0.8}
              onPress={() => handlePrize(30)}>
              <LazyImageBackground
                occupancy={'transparent'}
                imageUrl={buttonBlueIcon}
                width={125}
                height={46}
                style={[theme.flex.col, theme.flex.centerByCol]}>
                <View style={[theme.flex.row, theme.flex.center]}>
                  <LazyImage
                    occupancy={'transparent'}
                    imageUrl={moneyIcon}
                    width={25}
                    height={25}
                  />
                  <Text
                    fontSize={theme.fontSize.xl}
                    style={[theme.font.bold, {color: 'white'}]}>
                    x{singleAmount * batchCount}
                  </Text>
                </View>
                <Text
                  fontSize={theme.fontSize.s}
                  style={[theme.font.bold, {color: 'white'}]}>
                  {freeCount && freeCount >= batchCount
                    ? `${i18n.t('luckyspin.free')}x${batchCount}`
                    : `${i18n.t('luckyspin.spin')}x${batchCount}`}
                </Text>
              </LazyImageBackground>
            </NativeTouchableOpacity>
          </LazyImageBackground>
        </View>
      </LazyImageBackground>
      <LazyImageBackground
        occupancy={'transparent'}
        style={[styles.result, theme.flex.col, theme.flex.start]}
        imageUrl={resultIcon}
        width={261}
        height={166}>
        <Tab
          disableIndicator
          style={[styles.tabWrap, theme.margin.lrl, theme.margin.topl]}
          buttonStyle={[styles.nonepadding]}
          containerStyle={active => (active ? [styles.tabActive] : [])}
          titleStyle={active => [
            ...(active ? [styles.tabActiveText] : [styles.tabNotActiveText]),
            theme.font.fs,
            theme.font.bold,
          ]}
          value={tabIndex}
          onChange={handleChange}>
          <Tab.Item>{i18n.t('luckyspin.winner')}</Tab.Item>
          <Tab.Item>{i18n.t('luckyspin.mySpin')}</Tab.Item>
        </Tab>
        {tabIndex === 0 && (
          <FlatList
            style={[styles.resultList]}
            data={rankingList}
            initialNumToRender={4}
            ref={rankRef}
            renderItem={renderItem}
            onScroll={handleRankingScroll}
            getItemLayout={(data, index) => ({
              length: ITEM_HEIGHT,
              offset: ITEM_HEIGHT * index,
              index,
            })}
          />
        )}
        {tabIndex === 1 && (
          <FlatList
            style={[styles.resultList]}
            initialNumToRender={4}
            data={spinOrderList}
            renderItem={renderItem}
          />
        )}
        <BottomInfo
          freeCount={freeCount}
          onClose={() => handleHide()}
          login={login}
        />
      </LazyImageBackground>
      {renderGetModal}
      {renderToast}
    </View>,
    {
      backDropClose: true,
      onBackDropClose() {
        handleHide();
      },
      overlayStyle: {
        backgroundColor: 'transparent',
        shadowColor: 'transparent',
      },
    },
  );
  const handleShow = () => {
    setShowModal(true);
    show();
  };
  const handleHide = () => {
    if (animating) {
      return;
    }
    setShowModal(false);
    hide();
  };
  return {
    renderModal,
    show: handleShow,
  };
}

function isRankItem(item: RankingItem | SpinOrderItem): item is RankingItem {
  if ((item as RankingItem).userPhone != null) {
    return true;
  }
  return false;
}
