import {useTranslation} from 'react-i18next';
import NavTitle from '@/components/v2/basic/nav-title';
import React from 'react';

import Balance from '@/components/v2/business/balance/balance';

import LotteryContent from './lottery-content';

const Lottery = () => {
  const {t} = useTranslation();

  return (
    <div className="bg-bg w-full h-screen flex flex-col overflow-hidden">
      <NavTitle title={t('label.lottery')} rightNode={<Balance />} />
      <LotteryContent />
    </div>
  );
};

export default Lottery;
